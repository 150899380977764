import React, { useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TablePagination } from '@mui/material';

const AmortizationSchedule = ({ principal, annualInterestRate, totalYears }) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(12);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 12));
    setPage(0);
  };

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  const generateSchedule = () => {
    const monthlyInterestRate = Math.pow(1 + annualInterestRate / 100 / 2, 2 / 12) - 1;
    const totalPayments = totalYears * 12;
    const monthlyPayment = principal * (monthlyInterestRate * Math.pow(1 + monthlyInterestRate, totalPayments)) / (Math.pow(1 + monthlyInterestRate, totalPayments) - 1);

    let balance = principal;
    let schedule = [];

    for (let i = 1; i <= totalPayments; i++) {
      const interest = balance * monthlyInterestRate;
      const principalPayment = monthlyPayment - interest;
      balance -= principalPayment;
      schedule.push({
        paymentNumber: i,
        paymentAmount: monthlyPayment,
        principalPayment,
        interestPayment: interest,
        remainingBalance: balance > 0 ? balance : 0
      });
    }

    return schedule;
  };

  const schedule = generateSchedule();
  const emptyRows = rowsPerPage - Math.min(rowsPerPage, schedule.length - page * rowsPerPage);
  const visibleRows = schedule.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  return (
    <Paper>
      <TableContainer>
        <Table sx={{ minWidth: 650 }} aria-label="amortization schedule">
          <TableHead>
            <TableRow>
              <TableCell>Payment #</TableCell>
              <TableCell align="right">Payment Amount</TableCell>
              <TableCell align="right">Principal</TableCell>
              <TableCell align="right">Interest</TableCell>
              <TableCell align="right">Remaining Balance</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {visibleRows.map((row) => (
              <TableRow key={row.paymentNumber}>
                <TableCell component="th" scope="row">{row.paymentNumber}</TableCell>
                <TableCell align="right">{formatter.format(row.paymentAmount.toFixed(2))}</TableCell>
                <TableCell align="right">{formatter.format(row.principalPayment.toFixed(2))}</TableCell>
                <TableCell align="right">{formatter.format(row.interestPayment.toFixed(2))}</TableCell>
                <TableCell align="right">{formatter.format(row.remainingBalance.toFixed(2))}</TableCell>
              </TableRow>
            ))}
            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[12, 24, 36, 48]}
        component="div"
        count={schedule.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
};

export default AmortizationSchedule;
