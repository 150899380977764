import React from 'react';
import { TextField } from '@mui/material';

const NumberFormatInput = ({ label, value, onChange }) => {
  const handleChange = (event) => {
    onChange(event, parseFloat(event.target.value));
  }

  return (
    <TextField
      label={label}
      value={new Intl.NumberFormat().format(value)}
      onChange={handleChange}
      variant="outlined"
      type="text"
      inputProps={{
        style: { textAlign: 'right' },
      }}
      InputProps={{
        startAdornment: <span>$</span>,
      }}
    />
  );
};

export default NumberFormatInput;
