import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import moment from 'moment';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const MortgageBalanceChart = ({ principal, annualInterestRate, totalYears }) => {
  const generateChartData = () => {
    const monthlyInterestRate = Math.pow(1 + annualInterestRate / 100 / 2, 2 / 12) - 1;
    const totalPayments = totalYears * 12;
    const monthlyPayment = principal * (monthlyInterestRate * Math.pow(1 + monthlyInterestRate, totalPayments)) / (Math.pow(1 + monthlyInterestRate, totalPayments) - 1);

    let balance = principal;
    let principalData = [];
    let interestData = [];

    for (let i = 1; i <= totalPayments; i++) {
      const interest = balance * monthlyInterestRate;
      const principalPayment = monthlyPayment - interest;
      balance -= principalPayment;
      principalData.push(principalPayment);
      interestData.push(interest);
    }

    return { principalData, interestData };
  };

  const labels = Array.from({ length: totalYears * 12 }, (_, i) => moment().add(i, 'months').format('MMM YYYY'));
  const { principalData, interestData } = generateChartData();

  const data = {
    labels,
    datasets: [
      {
        label: 'Principal Paid',
        data: principalData,
        backgroundColor: 'rgba(54, 162, 235, 0.5)',
      },
      {
        label: 'Interest Paid',
        data: interestData,
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        stacked: true,
      },
      x: {
        stacked: true,
      },
    },
    interaction: {
      intersect: false,
      mode: 'index',
    },
  };

  return (
    <div style={{ height: '400px', width: '100%' }}>
      <Bar data={data} options={options} />
    </div>
  );
};

export default MortgageBalanceChart;
