import React from "react";
import PercentageInput from "./Common/PercentageInput";
import TextComponent from "./Common/TextComponent";
import SliderComponent from "./Common/SliderComponent";
import { Stack, TextField, Paper, Divider } from '@mui/material';

const SliderSelect = ({ data, setData }) => {
    const handleCapChange = (e) => {
        setData({ ...data, caprate: Number(e.target.value) });
    };

    const handleUnitsChange = (e) => {
        setData({ ...data, units: Number(e.target.value) });
    };

    const handleAverageRentChange = (e) => {
        setData({ ...data, averageRent: Number(e.target.value.replace(/[^\d.-]/g, '')) });
    };

    const handleExpensesChange = (e) => {
        setData({ ...data, expenses: e.target.value });
    };

    const handleRateChange = (e) => {
        setData({ ...data, rate: Number(e.target.value) });
    };

    const handleYearsChange = (e) => {
        setData({ ...data, years: Number(e.target.value) });
    };

    const handleAmountChange = (e) => {
        setData({ ...data, amount: Number(e.target.value) });
    };

    const handleVacancyChange = (e) => {
        setData({ ...data, vacancy: Number(e.target.value) });
    };

    return (
        <Paper sx={{ padding: 3 }}>
            <Stack gap={3}>
            <Divider textAlign="left"><h3>Property</h3></Divider>
                <TextField 
                    label="Units"
                    value={data.units}
                    onChange={handleUnitsChange}
                    variant="outlined"
                />
                <TextComponent 
                    label="Average Rent"
                    value={data.averageRent}
                    onChange={handleAverageRentChange}
                />
                <SliderComponent
                    min={2}
                    max={8}
                    unit="%"
                    amount={data.caprate}
                    label="Cap Rate"
                    value={data.caprate}
                    steps={0.25}
                    onChange={handleCapChange}
                />
                <SliderComponent
                    min={10}
                    max={80}
                    unit="%"
                    amount={data.expenses}
                    label="Expenses"
                    value={data.expenses}
                    steps={1}
                    onChange={handleExpensesChange}
                />
                <SliderComponent
                    min={0}
                    max={10}
                    unit="%"
                    amount={data.vacancy}
                    label="Vacancy"
                    value={data.vacancy}
                    steps={1}
                    onChange={handleVacancyChange}
                />
                <Divider textAlign="left"><h3>Loan</h3></Divider>
                <SliderComponent
                    min={30}
                    max={100}
                    unit="%"
                    amount={data.amount}
                    label="Loan Amount"
                    value={data.amount}
                    steps={1}
                    onChange={handleAmountChange}
                />
                <PercentageInput 
                    label="Rate"
                    value={data.rate}
                    onChange={handleRateChange}
                />
                <TextField 
                    label="Years"
                    value={data.years}
                    onChange={handleYearsChange}
                    variant="outlined"
                />
            </Stack>
        </Paper>
    );
};

export default SliderSelect;
