import React from "react";
import CurrencyTextField from "./CurrencyTextField"

const TextComponent = ({
    defaultValue,
    label,
    onChange,
    value
  }) => {
    return (
        <div>
            <CurrencyTextField
                label={label}
                variant="standard"
                value={value}
                currencySymbol="$"
                textAlign="right"
                outputFormat="string"
                defaultValue={defaultValue}
                onChange={onChange}
            />
        </div>
    )
  }
  
  export default TextComponent