import React, { useState, useEffect } from "react";
import { Grid, Paper, Tabs, Tab, Button, Drawer, List, ListItem, ListItemText, TextField, IconButton, Box } from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import Navbar from "./Components/Navbar";
import Result from "./Components/Result";
import SliderSelect from "./Components/SliderSelect";
import AmortizationSchedule from "./Components/AmortizationSchedule";
import MortgageBalanceChart from "./Components/MortgageBalanceChart";
import { Container } from "@mui/system";
import './App.css';

function App() {
  const defaultData = {
    amount: 75,
    rate: 4,
    vacancy: 1,
    averageRent: 2800,
    expenses: 30,
    units: 50,
    years: 30,
    caprate: 4.25,
    loanAmount: 0,
  };

  const [data, setData] = useState(JSON.parse(localStorage.getItem("latestState")) || defaultData);
  const [savedStates, setSavedStates] = useState(JSON.parse(localStorage.getItem("savedStates")) || []);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [editingState, setEditingState] = useState(null);
  const [highlightState, setHighlightState] = useState(null);
  const [loanAmount, setLoanAmount] = useState(0);

  useEffect(() => {
    localStorage.setItem("latestState", JSON.stringify(data));
  }, [data]);

  useEffect(() => {
    localStorage.setItem("savedStates", JSON.stringify(savedStates));
  }, [savedStates]);

  const handleSaveState = () => {
    const existingIndex = savedStates.findIndex((state) =>
      Object.keys(data).every((key) => data[key] === state[key])
    );
    if (existingIndex !== -1) {
      setHighlightState(existingIndex);
      setTimeout(() => setHighlightState(null), 2000);
      return;
    }
    const newState = { ...data, timestamp: Date.now(), name: `Value: ${data.value}` };
    setSavedStates([newState, ...savedStates]);
    localStorage.setItem("latestState", JSON.stringify(newState));
    setDrawerOpen(true);
  };

  const handleStateSelect = (selectedState) => {
    setData({ ...selectedState });
    setDrawerOpen(false);
    console.log('changing state', selectedState)
  };

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  const handleEditStateName = (index, name) => {
    const updatedStates = [...savedStates];
    updatedStates[index].name = name;
    setSavedStates(updatedStates);
    setEditingState(null);
  };

  const [tabIndex, setTabIndex] = useState(0);

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  const StateItem = ({ state, index }) => {
    const isHighlighted = index === highlightState;
    return (
      <ListItem key={state.timestamp} sx={isHighlighted ? { animation: 'shake 0.82s cubic-bezier(.36,.07,.19,.97) both' } : {}}>
        {editingState === index ? (
          <TextField
            defaultValue={state.name}
            onBlur={(e) => handleEditStateName(index, e.target.value)}
            autoFocus
            fullWidth
          />
        ) : (
          <ListItemText
            primary={state.name}
            secondary={new Date(state.timestamp).toLocaleString()}
            onClick={() => setEditingState(index)}
            style={{ cursor: 'pointer' }}
          />
        )}
        <Button onClick={() => handleStateSelect(state)}>View</Button>
      </ListItem>
    );
  };

  return (
    <div className="App">
      <Navbar />
      <IconButton onClick={toggleDrawer} sx={{ position: 'absolute', top: 10, left: 10 }}>
        <MenuIcon />
      </IconButton>
      <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer}>
        <Box sx={{ width: 250, padding: 2 }}>
          <List>
            {savedStates.map((state, index) => (
              <StateItem state={state} index={index} />
            ))}
          </List>
        </Box>
      </Drawer>
      <Container maxWidth="xl" sx={{ marginTop: 4 }}>
        <Tabs value={tabIndex} onChange={handleTabChange} align="center">
          <Tab label="Conventional Loan" />
          <Tab label="Construction Budget Generator" />
          <Tab label="Bill Comparison AI" />
        </Tabs>
      </Container>
      {tabIndex === 0 && (
        <Container maxWidth="xl" sx={{ marginTop: 4 }}>
        <Grid container spacing={5}>
          <Grid item xs={12} md={6}>
            <SliderSelect data={data} setData={setData} />
          </Grid>
          <Grid item xs={12} md={6}>
            <Paper sx={{height: '100%'}}>
              <Result data={data} setLoanAmount={setLoanAmount} />
              <Box sx={{
                  padding: 2,
                  textAlign: 'right'
                }}>
                <Button onClick={handleSaveState} variant="contained">Save Property</Button>
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <h2>Amortization Schedule</h2>
            <AmortizationSchedule principal={loanAmount} annualInterestRate={data.rate} totalYears={data.years} />
          </Grid>
        </Grid>
      </Container>
      )}
      {tabIndex === 1 && (
        <Container maxWidth="xl" sx={{ marginTop: 4 }}>
          <h1>Construction Budget Placeholder</h1>
        </Container>
      )}
      {tabIndex === 2 && (
        <Container maxWidth="xl" sx={{ marginTop: 4 }}>
          <h1>Bill Comparison AI Placeholder</h1>
        </Container>
      )}
    </div>
  );
}

export default App;
