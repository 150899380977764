import React from 'react';
import { Typography, Divider, List, ListItem, ListItemIcon, ListItemText, Stack } from '@mui/material';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import PaymentIcon from '@mui/icons-material/Payment';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import ReceiptIcon from '@mui/icons-material/Receipt';
import HotelIcon from '@mui/icons-material/Hotel';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import TimelineIcon from '@mui/icons-material/Timeline';
import BarChartIcon from '@mui/icons-material/BarChart';

const Result = ({ data, setLoanAmount }) => {
    const calculateMonthlyMortgagePayment = (principal, annualInterestRate, totalYears) => {
        const R = annualInterestRate / 100;
        const r = Math.pow(1 + R / 2, 2 / 12) - 1;
        const n = totalYears * 12;
        return principal * (r * Math.pow(1 + r, n)) / (Math.pow(1 + r, n) - 1);
    }

    const { caprate, amount, vacancy, rate, units, averageRent, expenses, years } = data;
    const monthlyIncome = averageRent * units;
    const yearlyIncome = monthlyIncome * 12;
    const vacancyAmount = yearlyIncome * (vacancy / 100);
    const totalExpenses = yearlyIncome * (expenses / 100);
    const NOI = yearlyIncome - totalExpenses - vacancyAmount;

    const capDec = caprate / 100;
    const value = (NOI / capDec);
    const loanAmount = value * (amount / 100);
    setLoanAmount(loanAmount);

    const monthlyPayment = calculateMonthlyMortgagePayment(loanAmount, rate, years);
    const annualDebtService = monthlyPayment * 12;
    const dscr = NOI / annualDebtService;

    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    });

    const ListItemComponent = ({ icon, primary, secondary }) => (
        <>
            <ListItem>
                <ListItemIcon>
                    {icon}
                </ListItemIcon>
                <ListItemText primary={primary} secondary={secondary} />
            </ListItem>
            <Divider />
        </>
    );

    return (
        <Stack gap={3}>
            <Divider textAlign="left"><h3>Property</h3></Divider>
            <List>
                <ListItemComponent icon={<MonetizationOnIcon />} primary="Value" secondary={formatter.format(value)} />
                <ListItemComponent icon={<ReceiptIcon />} primary="Expenses" secondary={formatter.format(totalExpenses)} />
                <ListItemComponent icon={<HotelIcon />} primary="Vacancy Amount" secondary={`${formatter.format(vacancyAmount)} (${vacancy}%)`} />
                <ListItemComponent icon={<BusinessCenterIcon />} primary="Net Operating Income" secondary={formatter.format(NOI)} />
            </List>
            <Divider textAlign="left"><h3>Loan</h3></Divider>
            <List>
                <ListItemComponent icon={<AccountBalanceIcon />} primary="Loan Amount" secondary={`${formatter.format(loanAmount)} (${amount}%)`} />
                <ListItemComponent icon={<CreditCardIcon />} primary="Monthly Payment" secondary={formatter.format(monthlyPayment)} />
                <ListItemComponent icon={<TimelineIcon />} primary="Annual Debt Service" secondary={formatter.format(annualDebtService)} />
                <ListItemComponent icon={<BarChartIcon />} primary="DSCR" secondary={dscr.toFixed(2)} />
            </List>
        </Stack>
    );
};

export default Result;