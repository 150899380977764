import React from 'react';
import { TextField } from '@mui/material';

const PercentageInput = ({label, value, onChange}) => {
  const handleChange = (event) => {
    let val = event.target.value;

    // Remove non-numeric characters except for the decimal point and clamp
    val = val.replace(/[^0-9.]/g, '');
    const percentage = Math.min(100, Math.max(0, parseFloat(val)));

    // Call onChange with the numeric value
    if (onChange) {
      onChange(event, isNaN(percentage) ? '' : percentage);
    }
  };
  
  return (
    <div>
      <TextField
        label={label}
        value={value}
        onChange={handleChange}
        variant="outlined"
        type="text"
        InputProps={{
          startAdornment: <span>%</span>,
        }}
        inputProps={{
          style: {
            textAlign: "right"
          }
        }}
      />
    </div>
  );
};

export default PercentageInput;
